<template>
    <input :type="type" :value="value"  :v-model="value" :placeholder="placeholder" :required="required" @input="update($event.target.value)" />
</template>

<script>
export default {
    props: {
        value: String,
        placeholder: String,
        type: { type:String, default:'text' },
        required: { type:Boolean, default:false }
    },
    methods: {
        update: function (value) {
            this.$emit('update', value)
        }
    }
}
</script>

<style scoped lang="scss">
input {
    border:1px solid var(--layout-border-color); 
    padding:8px 10px;
    box-sizing:border-box;
    font-size:16px;
    width:100%;
    border-radius:0px;
    vertical-align:middle;
}
</style>
